import {PROTOCOL_HOSTNAME} from '@/constants/global';

export type PageType = 'samples' | 'interactions' | 'coverage';

const useUrlsData = (slug: string) => {
  const pages: Record<PageType, string> = {
    samples: `/sample-store/${slug}`,
    interactions: `/therapies/${slug}/interactions`,
    coverage: `/therapies/${slug}#coverage`,
  };

  const getUrl = (href: string) => new URL(href, PROTOCOL_HOSTNAME).toString();
  const getUrlByPage = (page: PageType) =>
    Object.keys(pages).includes(page) ? getUrl(pages[page]) : null;

  return {
    getUrlByPage,
  };
};

export default useUrlsData;
