import {Transition} from '@headlessui/react';
import {BottomSheet, Button, Icon, IconButton, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useState} from 'react';
import {InterstitialModal} from '@/components';
import {REMS_DEFAULT_URL} from '@/constants/rems';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useRemsBySetId} from '@/queries';

const RemsInfo = () => {
  const [showRemsInfo, setShowRemsInfo] = useState(false);
  const [toggleIntersitialModal, setToggleIntersitialModal] = useState(false);
  const {isMobile} = useUserAgentContext();

  const {setId, drugName} = useCurrentDrug();

  const {data, isFetching} = useRemsBySetId(setId);

  if (!data || isFetching) {
    return null;
  }

  const {remsid, website} = data;

  const toggleShowRemsInfo = () => {
    setShowRemsInfo(!showRemsInfo);
  };

  const handleToggleIntersitialModal = () => {
    setShowRemsInfo(false);
    setToggleIntersitialModal(!toggleIntersitialModal);
  };

  const handleGotoRemsPage = () => {
    window.location.href = website ?? `${REMS_DEFAULT_URL}${remsid}`;
  };

  const renderRemsInfo = () => (
    <div className='flex flex-col gap-3'>
      <div className='flex items-start gap-1'>
        <Text>
          {drugName} is available <b>only through a restricted program</b> under
          an FDA Risk Evaluation and Mitigation Strategy (REMS)
        </Text>
        <IconButton
          id='close-dropdown'
          onClick={toggleShowRemsInfo}
          className='shrink-0'
          icon={
            <Icon
              name='Cancel'
              className='flex h-4 w-4 items-center text-neutral-primary'
            />
          }
        />
      </div>
      <Button id='go-to-rems' stretched onClick={handleToggleIntersitialModal}>
        Go To REMS Program Information
      </Button>
    </div>
  );

  return (
    <div className='relative'>
      <Button
        id='remsInfo'
        className='h-8 gap-1 whitespace-nowrap rounded-full'
        onClick={toggleShowRemsInfo}
        iconLeft='InfoCircle'
        classNameIconLeft='!text-gold-800'
        variant='secondary'>
        REMS INFO
      </Button>
      {isMobile ? (
        <BottomSheet
          isOpen={showRemsInfo}
          detent='content-height'
          onClose={toggleShowRemsInfo}
          containerClassName='pb-6'
          headerClassName='pb-1'>
          {renderRemsInfo()}
        </BottomSheet>
      ) : (
        <Transition
          as='div'
          show={showRemsInfo}
          className={clsx([
            'absolute z-10 box-border rounded-md bg-white p-4 shadow-lg sm:left-[-216px]',
            'w-[328px] ring-1 ring-black ring-opacity-5 focus:outline-none',
          ])}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'>
          {renderRemsInfo()}
        </Transition>
      )}

      <InterstitialModal
        isOpen={toggleIntersitialModal}
        resourceName={'rems info'}
        brandName={drugName}
        shareLink={''}
        confirmLeave={handleGotoRemsPage}
        goBack={handleToggleIntersitialModal}
      />
    </div>
  );
};

export default RemsInfo;
